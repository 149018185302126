@import "~antd/dist/antd.css";

$background-color: #FCFCFC;

.app-layout{
    background: $background-color;
}

.App{
    background: $background-color;
}

.ant-input{
    background: $background-color;
}

.ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span{
    transition: none;
    webkit-transition: none;
}

* {
    font-family: "Lato";
}

body {
    background: $background-color;
}

/* Register Page */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
}

@mixin v-align($position: absolute) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%); /* IE */
    -moz-transform: translateY(-50%); /* Firefox */
    -webkit-transform: translateY(-50%); /* Safari and Chrome */
    -o-transform: translateY(-50%);
}

.centered1140 {
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.mobile {
    display: none;
}

.ant-layout{
    background: transparent;
}

.ant-layout-sider, .ant-menu-dark{
    background: #111 !important;
}

@media (max-width: 850px) {
    .mobile {
        display: block
    }

    .desktop {
        display: none;
    }
}

.landing-page {
    background: white;

    //.section {
    //    height: 100vh;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    flex-direction: column;
    //
    //    h1 {
    //        max-width: 700px;
    //    }
    //
    //    .logo {
    //        max-width: 500px;
    //        display: block;
    //        display: none;
    //    }
    //}

    .landing-container{
        margin-left: auto;
        margin-right: auto;
        width: 1360px;
    }

    #downloads{
        //border: 1px solid green;
        width: 100%;
        //min-height: 300px;
        //margin-bottom: 30px;
        text-align: center;
        padding-top: 120px;
        padding-bottom: 80px;
        h2{

        }
        .download-options{
            display: flex;
            justify-content: center;
            //max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 40px;
            flex-wrap: wrap;
            button, a{
                border-radius: 500px;
                line-height: 20px;
                font-weight: 700;
                text-transform: none;
                letter-spacing: normal;
                box-sizing: border-box;
                font-family: spotify-circular, Helvetica, Arial, sans-serif;
                -webkit-tap-highlight-color: transparent;
                display: inline-block;
                position: relative;
                text-align: center;
                text-decoration: none;
                touch-action: manipulation;
                transition-duration: 33ms;
                transition-property: background-color, border-color, color, box-shadow, filter, transform;
                user-select: none;
                vertical-align: middle;
                align-self: center;
                padding: 14px 0px;
                height: 48px;
                width: 160px;
                font-size: 16px;
                background-color: white;
                border: 1px solid darkgrey;
                color: #222222;
                cursor: pointer;
                margin-left: 20px;
                margin-right: 20px;


                @media (max-width: 768px) {
                    margin-top: 20px;
                    width: inherit;
                    padding: 14px 20px;
                }

                &:hover{
                    //border-color: #0085f2;
                    transition: height .5s;
                    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,133,242,0.35);
                    box-shadow: 0px 0px 15px 0px rgba(0,133,242,0.35);
                }
                &.selected{
                    background-color: #0085f2;
                    color: white;
                }
            }
        }
        .gray-bg{
            margin-top: 30px;
            background-color: #e0e0e0;
            padding: 45px 0px;
            position: relative;

            button.close{
                color: black;
                position: absolute;
                top: 10px;
                right: 20px;
                font-size: 16px;
            }

            a{
                margin-top: 8px;
                border-radius: 500px;
                line-height: 20px;
                font-weight: 700;
                text-transform: none;
                letter-spacing: normal;
                box-sizing: border-box;
                font-family: spotify-circular, Helvetica, Arial, sans-serif;
                -webkit-tap-highlight-color: transparent;
                display: inline-block;
                position: relative;
                text-align: center;
                text-decoration: none;
                touch-action: manipulation;
                transition-duration: 33ms;
                transition-property: background-color, border-color, color, box-shadow, filter, transform;
                user-select: none;
                vertical-align: middle;
                align-self: center;
                padding: 12px 30px;
                height: 44px;
                font-size: 15px;
                background-color: #fafafa;
                //color: #222222;
                cursor: pointer;
                margin-left: 20px;
                margin-right: 20px;
                color: #333355;

                @media (max-width: 768px) {
                    width: inherit;
                    padding: 14px 20px;
                    margin: 10px;
                }

                &:hover{
                    //border-color: #0085f2;
                    transition: height .5s;
                    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,133,242,0.35);
                    box-shadow: 0px 0px 15px 0px rgba(0,133,242,0.35);
                }
            }
        }
        pre{
            max-width: 1024px;
            margin-left: auto;
            margin-right: auto;
            text-align: left;
        }
        @media (max-width: 1000px) {
            pre{
                max-width: 96%;
                margin-left: auto;
                margin-right: auto;
                text-align: left;
            }
        }

        .existing-website{
            background-color: #111!important;
            color: white !important;
            min-width: 147px;
        }

        .windows>a, .linux>button, .mac>a{
            display: flex;
            justify-content: center;
            .mac-logo{
                display: block;
                margin-right: 0px;
                position: relative;
                top: -3px;
                left: -5px;
                img{
                    width: 100%;
                    max-width: 20px;
                }
            }
            .windows-logo{
                display: block;
                margin-right: 10px;
                position: relative;
                top: -2px;
                svg{
                    width: 100%;
                    max-width: 20px;
                    max-height: 20px;
                }
            }
            .linux-logo{
                display: block;
                margin-right: 0px;
                position: relative;
                top: -3px;
                left: -5px;
                svg{
                    width: 100%;
                    max-width: 27px;
                    max-height: 27px;
                }
            }
        }
    }

    nav {
        //max-width: 1306px;
        position: fixed;
        padding: 16px;
        width: 100% !important;
        display: flex;
        justify-content: space-between;
        z-index: 20;
        //background: $background-color;
        background: white;
        left:0;
        right:0;
        max-width: 100%;
        //box-shadow: 0 2px 6px 0 rgba(0,0,0,.12), inset 0 -1px 0 0 #dadce0;
        //display: -ms-flexbox;

        .dropdown-menu-mobile {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .anchors {
            display: flex;
            flex-wrap: nowrap;
            a {
                color: black;
                margin: 0px 25px;
                font-size: 16px;
                padding: 4px 0px;

                svg{
                    max-height: 29px;
                    position: relative;
                    top: -3px;
                }
            }

            a:hover {
                color: #1890ff;
            }
        }

        @media (max-width: 1000px) {
            .anchors a{
                margin: 0 15px;
            }
        }

        .actions {
            display: flex;
            flex-wrap: nowrap;

            button {
                border: none;
                outline: none;
                box-shadow: none;
                background-color: transparent;
                color: black;
                font-size: 16px;
            }

            .login-button {
                color: #1890ff;
            }
        }
    }

    .form-button {
        width: 100%;
    }

    .intro-section {
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 100vh;
        max-height: 1024px;
        //padding: 1rem;

        .info {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            flex-direction: column;

            .content {
                max-width: 400px;

                h1 {
                    font-size: 50px;

                    & > div {
                        position: relative;
                        top: -3px;
                    }
                }

                .body {
                    color: #5f6368;
                    font-size: 22px;
                    font-weight: 400;
                    margin-top: 20px;
                    line-height: 30px;
                }

                .title {
                    font-size: 60px;
                    font-family: Lato;
                }
            }
        }

        video {
            width: 50%;
            padding-right: 30px;
        }
    }

    #info-section{
        background: #222!important;
        padding: 60px 0px;
        color: white;

        .info-intro {
            text-align: center;
            max-width: 700px;
            //padding-top: 60px;
            //padding-bottom: 60px;
            margin-left: auto;
            margin-right: auto;
            width: 96%;

            h2 {
                //font-size: 40px;
                //font-family: Lato;
                //font-size: 50px;
                font-size: 45px;
                font-weight: 700;
                color: white;
                //span{
                //    color: #1890ff;
                //}
            }

            p {
                //font-family: "Lato", Arial;
                //color: #5f6368;
                //font-size: 30px;
                //margin-bottom: 0px;
                //font-weight: 400;
                color: whitesmoke;
                span {
                    color: black;
                    font-weight: 800;
                    //text-decoration: underline;
                }
            }
        }

        .interactive-section {

            .info {
                text-align: center;
                margin-bottom: 40px;

                svg, p {
                    color: #1a73e8;
                    display: inline-block;
                    text-align: center;
                }

                svg {
                    margin-right: 4px;
                    font-size: 26px;
                }

                p {
                    color: #1a73e8;
                    display: inline-block;
                    text-align: center;
                    font-weight: 700;
                    margin-left: 4px;
                    margin-bottom: 0;
                    font-family: "Nunito Sans", sans-serif;
                    position: relative;
                    top: 1px;
                    font-size: 17px;
                }

                .subtitle {
                    font-size: 15px;
                    margin-top: 4px;
                    color: whitesmoke;
                }
            }

            .video-container {
                margin-left: auto;
                margin-right: auto;
                width: 1200px;
                display: block;

                .rh5v-DefaultPlayer_component {
                    background-color: #FFF;
                }
            }
            @media (max-width: 1200px) {
                .video-container {
                    width: 98% !important;
                    margin: 0 1%;
                }
            }

        }
    }

    h3 {
        width: 20%;
        text-align: left;
        font-size: 24px;
        margin-top: 30px;
    }

    h2 {
        //font-size: 40px;
        //font-family: Lato;
        //font-size: 50px;
        font-size: 45px;
        font-weight: 700;
        margin-bottom: 8px;

        //span{
        //    color: #1890ff;
        //}
    }

    .subtitle {
        color: #5f6368;
        font-size: 18px;
        margin-bottom: 40px;
        font-weight: 400;
    }

    .features-section {
        text-align: center;
        max-width: 1140px;
        padding-top: 80px;
        padding-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        width: 96%;

        h2 {
            margin-bottom: 28px;
        }

        .feature-section {
            display: flex;
            width: 100%;
            margin-bottom: 20px;

            .features {
                width: 80%;
                display: flex;
                flex-wrap: wrap;

                .feature {
                    width: 30%;
                    margin: 1%;
                    text-align: left;
                    flex-basis: 30%;
                    //flex-basis: 100%;

                    svg {
                        height: 20px !important;
                        display: none !important;
                    }

                    .anticon svg {
                        width: 100%;
                    }

                    h4 {
                        text-transform: uppercase;
                        font-size: 13px;
                        font-weight: 700;
                        margin-top: 13px;
                        margin-bottom: 5px;
                    }

                    p{
                        color: #444;
                        margin-bottom: 0px;
                    }
                }
            }
        }

        & #collaborate {
            h3{
                //color: #444;
                color: rgb(67, 160, 71);
            }
            svg {
                color: rgb(67, 160, 71);
            }
        }

        & #organize {
            h3{
                //color: #444;
                color: rgb(255, 179, 0);
            }
            svg {
                color: rgb(255, 179, 0);
            }
        }

        & #practical {
            h3{
                //color: #444;
                color: rgb(30, 136, 229);
            }
            svg {
                color: rgb(30, 136, 229);
            }
        }
    }

    .aboutus-section {
        text-align: center;
        padding-top: 60px;

        .img-container {
            width: 100%;
            position: relative;

            & > img {
                width: 100%;
            }

            .arrow.sarah {
                width: 10%;
                position: absolute;
                top: 29%;
                //left: 21%;
                left: 19%;
                z-index: 2;
                transform: rotate(45deg);
            }

            .arrow.joan {
                width: 10%;
                position: absolute;
                top: 27%;
                //right: 24%;
                right: 20%;
                z-index: 2;
                transform: rotate(133deg);
            }
        }

        .pinfo {
            width: 30%;
            position: absolute;
            z-index: 2;
            color: white;

            h4 {
                color: white;
                font-size: 60px;
                margin-bottom: 0px;
                font-family: 'Amatic SC', cursive;
            }

            p {
                //font-size: 20px;
                font-size: 27px;
                font-family: 'Amatic SC', cursive;
            }

            a {
                svg {
                    width: 35px;
                    //height: 40px;
                    color: white;
                    display: block;
                }
            }
        }

        .sarah-info {
            //top: 17%;
            //left: 12%;
            //text-align: left;
            top: 9%;
            left: 3%;
            text-align: left;
            width: 20%;
        }

        .joan-info {
            //top: 13%;
            //right: 12%;
            //text-align: right;

            top: 7%;
            right: 3%;
            text-align: right;
            width: 20%;

            a svg {
                float: right;
            }
        }

    }

    #faq{
        text-align: left;
        max-width: 1140px;
        //max-width: 1000px;
        padding-top: 80px;
        padding-bottom: 20px;
        margin-left: auto;
        margin-right: auto;

        h2{
            text-align: center;
        }

        .faqs{
            margin-top: 24px;
            border: none;

            .ant-collapse{
                border: none !important;
            }

            .ant-collapse-header{
                //padding-left: 20px;
                font-size: 20px;
                background-color: white;
                //border-bottom: 1px solid #ccd6dd;
                padding-top: 1.3rem;
                padding-bottom: 1rem;
                cursor: pointer;
                font-weight: 500;

                svg{
                    //display: none;
                    margin-top: 12px;
                }
            }

            p{
                //color: #5f6368;
                color: #555;
                font-size: 17px;
                font-weight: 400;
                margin-bottom: 0px;
                padding-left: 10px;
            }
        }

        .faq-view-more{
            display: block;
            margin-top: 20px;
            margin-left:auto;
            margin-right:auto;
        }

        //li{
        //    border-bottom: 1px solid #ccd6dd;
        //    padding-top: 1.5rem;
        //    padding-bottom: 1rem;
        //
        //    padding-left: 0;
        //    list-style: none;
        //
        //    h4{
        //        //color: #657786;
        //    }
        //
        //    p{
        //        color: #5f6368;
        //        font-size: 18px;
        //        font-weight: 400;
        //    }
        //}
    }

    @media (max-width: 850px) {
        #faq{
            padding-top: 50px;
            padding-bottom: 10px;
            padding-left:5%;
            padding-right:5%;
        }
    }

    .contactus-section {
        text-align: center;
        padding-top: 80px;
        display: flex;
        padding-bottom: 60px;

        & p {
            //max-width: 540px;
            //margin-right: auto;
            //margin-left: auto;
        }

        .centered1140 {
            display: flex;
        }

        .left {
            text-align: left;
        }

        .left, .right {
            width: 50%;
            text-align: left;
        }

        .right {
            padding: 13px 40px;

            textarea {
                min-height: 100px;
            }

        }

    }

    .proud-section {
        background: #001529;
        padding: 9px;

        p {
            text-align: center;
            letter-spacing: 1px;
            color: white;
            margin-bottom: 0px;

            svg {
                width: 20px;
                //margin-left: 10px;
            }
        }
    }
}

@media (max-width: 850px) {
    .landing-page {
        width: 100%;

        h3 {
            text-align: center;
            width: auto;
        }
        .container{
            width: 100%;
        }
        .mobile-logo {
            color: black;
            margin: 25px;
            font-size: 16px;
            margin-left: 0px;
            top: 2px;
            position: relative;

            svg{
                max-height: 32px;
                position: relative;
                top: -3px;
                float: left;
                text-align: left;
                max-width: 120px;
            }
        }

        .dropdown-menu-mobile {
            border: 1px solid #ddd;
            width: 94% !important;
            position: absolute;
            top: 52px;
            flex-direction: column;
            background-color: $background-color;
            z-index: 2;
            padding: 7px;
            padding-bottom: 15px;
            display: none !important;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;

            .anchors {
                .anchor-logo {
                    display: none;
                }

                display: flex;
                flex-direction: column;

                a {
                    margin: 9px;
                    text-align: center;
                }
            }

            .actions {
                text-align: center;
                width: 100%;
                display: flex;
                flex-direction: column;

                button {
                    margin: 6px;
                }
            }

            &.show-menu {
                display: flex !important;
            }
        }

        .mobile-dropdown {
            background: $background-color;
            border: none;
            position: absolute;
            top: 16px;
            right: 10px;
            font-size: 17px;
        }

        .intro-section {
            height: initial;
            padding-top: 120px;
            width: 100%;
            flex-direction: column;

            .info {
                width: 100%;
                margin-bottom: 20px;
                padding: 0px 10%;

                .content h1 {
                    text-align: center;
                }
            }

            video {
                width: 100%;
                padding: 15px 9%;
                margin-right: auto;
                margin-left: auto;
            }
        }

        .info-section {
            padding: 0px 10%;
        }

        .features-section .feature-section {
            display: block;

            .features {
                width: 100%;
                padding: 0px 5%;

                .feature {
                    width: 44%;
                    margin: 3%;
                    text-align: left;
                    flex-basis: 44%;
                }
            }
        }

        .aboutus-section {
            .arrow {
                display: none;
            }

            .sarah-info {
                top: 1%;
                left: 3%;
                width: 43%;
            }

            .joan-info {
                top: 1%;
                width: 43%;
            }

            .about-us-height {
                height: 500px !important;
            }

            .react-parallax-bgimage {
                height: 100%;
            }
        }

        .contactus-section {
            .left, .right {
                width: 100%;
                padding: 0px 7%;
            }

            .centered1140 {
                display: initial;
            }
        }
    }

    .landing-drawer {
        .ant-drawer-content-wrapper {
            width: 100% !important;
        }
    }
}


.register-panel {
    .register-options {
        display: flex;
        flex-direction: column;

        button {
            border-radius: 2px;
            box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px 0;
            border-color: transparent;
            height: 50px;
            width: 300px;
            margin: 10px auto;
        }

        .google-register img {
            position: relative;
            top: -3px;
            margin-right: 10px;
        }

        .facebook-register img {
            max-width: 46px;
            position: relative;
            top: -2px;
            margin-right: -4px;
        }
    }

    .back-button {
        cursor: pointer;
        display: block;
        width: fit-content;
    }
}


.login-panel {

    .or {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        color: black;
        font-size: 15px;
    }

    .google-signin, .facebook-signin {
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px 0;
        border-color: transparent;
        height: 50px;
        width: 300px;
        display: block;
        margin: 10px auto;
    }

    .google-signin img {
        position: relative;
        top: -3px;
        margin-right: 10px;
    }

    .facebook-signin img {
        max-width: 20px;
        position: relative;
        top: -2px;
        margin-right: 11px;
    }
}


.notifications-button {
    border: 1px solid #ccc;
    align-self: center;
    width: 30px;
    height: 30px;
    border-radius: 28px;
    //padding-left: 1px;
    text-align: center;
    margin-right: 15px;
    cursor: pointer;
    color: #888;

    .ant-badge {
        padding-top: 6px !important;
    }

    .ant-badge-count {
        font-size: 10px;
        min-width: 15px;
        height: 15px;
        display: block;
        line-height: 15px;
        padding: 0 4px;
        top: 5px;
        right: -5px;
    }
}

.add-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: left;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    -webkit-box-pack: left;
    -webkit-justify-content: left;
    -ms-flex-pack: left;
    justify-content: left;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #EBECF0;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    padding: 7px 11px;
    padding-bottom: 6px;
    max-width: 250px;

    .add-button {
        background-color: transparent;
        color: #444;
        border: none;
        padding: 7px;

        .anticon-plus {
            position: relative;
            top: -3px;
            margin-right: 3px;
        }
    }

    textarea {
        resize: none;
        max-width: 226px;
        border: 1px solid rgba(200, 200, 200, 0.5);
        border-radius: 3px;
        padding: 4px 8px;
    }

    .close-button {
        border: none;
        position: relative;
        top: 0px;
        margin-left: 5px;
        background-color: transparent;

        svg {
            width: 21px;
            height: 21px;
        }
    }
}

.notifications-modal {
    top: 55px;
    right: 6px;
    position: fixed;
    width: 384px;
    height: 372px;
    //border: 1px solid #aaa;
    background-color: $background-color;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border-radius: 3px;
    box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25), 0 0 0 1px rgba(9, 30, 66, 0.08);
    box-sizing: border-box;
    outline: 0;
    overflow: hidden;
    z-index: 9;
    padding: 11px 19px;

    .header {
        width: 100%;
        text-align: center;
        margin-bottom: 8px;
        position: relative;
    }

    .close-button {
        position: absolute;
        top: 9px;
        right: 10px;
        font-size: 18px;
        cursor: pointer;
    }

    .separator {
        margin-right: auto;
        margin-left: auto;
        background-color: #ccc;
        height: 1px;
        width: 100%;
    }

    .notifications-list {
        overflow: scroll;
        height: 100%;
        padding-bottom: 3px;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        }

        .date {
            font-size: 11px;
            color: #ababab;
            position: relative;
            top: 3px;
        }

        .loading-card {
            border-bottom: 1px solid #ccc;
            padding: 10px 5px;
            padding-top: 4px;
            text-align: center;
            padding-bottom: 0px;
            margin-bottom: 0px;
            height: 40px;

            .spinner-border {
                float: none;
                color: #1890ff;
                width: 20px;
                height: 20px;
            }
        }

        .notification-card {
            border-bottom: 1px solid #ccc;
            padding: 10px 5px;
            padding-top: 4px;
            position: relative;

            p {
                margin-bottom: 0px;
            }

            .new {
                background-color: #ff4d4f;
                color: white;
                border-radius: 17px;
                font-weight: 900;
                font-size: 8px;
                position: absolute;
                right: 8px;
                padding: 0px 5px;
                top: 10px;
                height: 16px;

                p {
                    position: relative;
                    top: -2px;
                }
            }

            .username {
                font-weight: 600;
            }

            .team-name {
                color: #1890ff;
                font-weight: 600;
            }
        }
    }
}

.landing-drawer {
    .notification {
        margin-bottom: 20px;
    }

    .center {
        //min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .login-form-button {
        width: 60%;
        margin-top: 30px;
        padding: 9px;
        height: inherit;
        margin-left: auto;
        margin-right: auto;
        display: block;
        font-size: 16px;
        font-weight: 900;
    }

    .forget-link {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 8px;
    }

    .other-actions {
        position: absolute;
        top: 30px;

        button {
            color: black;
            display: inline-block;
            background-color: transparent;
            border: 0px;
            box-shadow: none;

            h5 {
                color: #333;
            }

            h5:hover {
                color: #1890ff;
            }
        }
    }

    h3 {
        margin-bottom: 0px;
    }

    .options {
        position: relative;
        top: -3px;
    }

    .ant-drawer-body{
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .landing-drawer {
        .other-actions {
            position: initial;
            top: initial;
        }
    }
}

.invite-user-modal {
    .notification {
        margin-bottom: 12px;
    }
}

.app-layout {
    .logo-container {
        display: flex;

        .logo {
            height: 32px;
            //background: rgba(255, 255, 255, 0.2);
            margin: 16px;
            cursor: pointer;
            width: 90%;
            flex-basis: 90%;
            svg{
                position: relative;
                top: -4px;
                max-width: 91px;
                padding-left: 10px;
            }
        }

        .trigger {
            font-size: 18px;
            line-height: 64px;
            padding: 0 24px;
            cursor: pointer;
            transition: color 0.3s;
            color: rgba(255, 255, 255, 0.2);
            flex-grow: 1;
            @media (max-width: 768px) {
                display: none;
            }
        }

        .trigger:hover {
            color: white;
        }
    }

    > section.ant-layout {
        height: 100vh;
    }

    .site-layout {
        .site-layout-background {
            //background: #fff;
            overflow: auto;
        }

        .nav-user-dropdown {
            position: absolute;
            top: 14px;
            //top: 2%;
            right: 2%;
            display: block;
            padding: 0px;

            .mobile-toggle-sidebar {
                display: none;
            }

            .user-dropdown{
                padding: 8px;
                color: rgba(0,0,0,.7);
                .anticon{
                    position: relative;
                    top: -2px;
                    right: -3px;
                    svg{
                        max-width: 11px;
                    }
                }
            }
        }
    }

    .side-menu {
        .ant-menu-item-divider {
            margin: 15px 20px;
            background-color: #888;
        }

        .ant-menu-item .anticon + span,
        .ant-menu-submenu-title .anticon + span {
            // menu entry text
        }

        .ant-menu-item .anticon,
        .ant-menu-submenu-title .anticon {
            // Icon
            position: relative;
            top: -3px;
        }

        .boards-menu-entry {
            margin-bottom: 0px;
        }

        .create-board-side-button {
            margin: 0px;

            & > .anticon {
                position: relative;
                top: -3px;
            }
        }

        .board-menu-item {
            margin: 0px;

            & > .anticon {
                font-size: 10px;
                position: relative;
                top: -3px;
            }

            & > .anticon.anticon-star {
                font-size: 12px;
            }
        }
    }

    .page-content {
        // padding-left: 20px;
        // padding-top: 20px;
        //padding: 2%;
    }
}

@media (max-width: 768px) {
    .app-layout {
        background-color: $background-color;

        .site-layout {
            min-height: initial;
            background-color: $background-color;

            .page-content {
                height: fit-content;
            }

            .nav-user-dropdown {
                position: initial;
                display: flex;
                justify-content: space-between;
                background: $background-color;
                padding: 7px 11px;
                border-bottom: 1px solid #DDD;

                .mobile-toggle-sidebar {
                    display: block;

                    button {
                        color: #888;
                        background-color: $background-color;
                        border-radius: 2px;
                        border: 1px solid #ccc;
                        outline: none;
                    }
                }
            }
        }

        .page-inner {
            padding: 12px 4%;
        }

        .site-layout-background {
            background: #fff;
            min-height: initial;
        }
    }

    .ant-menu-inline-collapsed-tooltip {
        display: none;
    }
}

.metrics {
    display: flex;
    justify-content: center;

    & > div {
        width: 250px;

        p {
            text-align: center;
        }

        .value {
            font-weight: 700;
            font-size: 40px;
            font-family: "Nunito Sans", sans-serif;
            margin-bottom: 0px;
            text-align: center;
        }
    }
}

.create-board-modal {
    .vertical-radio {
        label {
            display: block;
        }

        .ant-radio-wrapper {
            display: block;
            height: 30px;
            line-height: 30px;
        }
    }

    .actions {
        text-align: right;

        .cancel-button {
            margin-right: 10px;
        }
    }
}

.users-pagination {
    display: flex;
    list-style: none;
    padding: 0px;
    justify-content: center;

    li {
        margin: 10px;

        button {
            border: 0px;
            background-color: transparent;
            outline: none;
            box-shadow: none;
            font-size: 15px;
        }

        &.selected {
            font-weight: 900;
            color: #1e88e5;
        }
    }
}

.add-board-entry {
    padding-left: 24px;
}

.blue-link {
    color: #1890ff;
    background-color: transparent;
    border: 0px;
    box-shadow: none;
    margin: 0px;
    padding: 0px;
}

.main-container {
    //max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    // margin-top: 30px;
    // margin-bottom: 30px;
}

.site-layout-content {
    background: #fff;
    padding: 24px;
    min-height: 280px;
}

#components-layout-demo-top .logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 24px 16px 0;
    float: left;
}

.sidebar {
    /* position: fixed; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 10;
    padding: 20px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

#sidebar-wrapper {
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

.sidebar .nav-item {
    margin-left: 15px;
}

.create-board-page {
    padding: 40px;
}

.ant-layout-sider-trigger {
    display: none;
}

.board-page {
    margin-left: initial;
    width: 100%;

    & > h4 {
        width: fit-content;
    }

    .actions {
        display: flex;
        // justify-content: space-between;

        .spinner-border {
            float: none;
            position: absolute;
            color: #1890ff;
            right: 171px;
            width: 30px;
            height: 30px;
            top: 24px;
        }
    }

    .actions .edit-button button {
        padding: 0px;
        font-size: 22px;
        border: transparent;
        background: $background-color;
    }

    .right-actions {
        display: flex;
        align-items: flex-start;

        .share-button {
            position: relative;
            top: 5px;
            margin-left: 10px;
            background: $background-color;
        }

        .delete-board {
            color: #ff4d4f;
        }
    }

    .favorite > span {
        width: 60px;
        font-size: 23px;
        position: relative;
        top: 8px;
    }

    .favorite .unfavorite-cta {
        color: #40a9ff;
    }

    .second-line {
        //display: flex;
        margin-top: 5px;

        .view-selector {
            svg {
                position: relative;
                top: -2px;
            }
        }

        .calendar-selector {

            .calendar-select-tables {
                min-width: 140px;
                margin-left: 10px;
            }

        }
    }

    .kanban-container {
        display: flex;
    }
}


@media (max-width: 768px) {

    .app-layout .page-inner.page-boards-padding {
        padding: 0px;
    }

    .boards-list-page {
        padding: 15px 4%;
    }

    .board-page {
        max-width: 100%;

        .actions {
            padding: 15px 4%;

            .spinner-border {
                top: initial;
                bottom: 10px;
                right: 10px;
            }
        }

        .board-container {
            width: 100%;
            display: block;
            margin-left: 0px;
            overflow-x: scroll;

            .tables-container {
                padding: 10px 21px;
                margin-top: 0px;

                .add-row-action {
                    flex-wrap: nowrap;

                    input {
                        min-width: 50%;
                    }
                }
            }
        }
    }
}


.table-menu {
    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
        padding: 6px 22px;
    }

    .delete-table {
        color: #ff4d4f;
    }
}

.ant-dropdown-menu-submenu {
    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
        padding: 5px 20px;
    }
}

.submenu-selected{
    background-color: #f5f5f5 !important;
}

.view-selector {

    svg {
        position: relative;
        top: -3px;
        margin-right: 3px;
        width: 15px;
        height: 17px;
        color: #007bff;
    }

    .disabled span {
        color: #bbb;
    }

    .disabled svg {
        color: gray;
    }
}

.configure-views {


    h3 {
        font-size: 18px;
        font-weight: 300;
    }

    .calendar-section {
        .options {
            display: flex;

            label {
                padding-left: 3px;
            }

            .start-date, .end-date {
                width: 50%;
                flex-basis: 50%;

                .ant-select {
                    width: 80%;
                }
            }
        }
    }

    .kanban-section {
        margin-top: 30px;

        label {
            padding-left: 3px;
        }

        .kanban-status {
            width: 50%;

            .ant-select {
                width: 80%;
            }
        }
    }

    .not-available {
        //color: #ff4d4f;
    }
}

.users-and-teams {
    margin-left: 40px;
    position: relative;
    top: 4px;

    p {
        margin-bottom: 2px;
        font-size: 13px;
    }

    .list {
        position: relative;
        height: 37px;
        cursor: pointer;

        > .user-team-entry {
            display: inline-flex;
            position: absolute;

            .user-thumb {
                margin-left: 0px;
            }

            .user-initials {
                padding-top: 6px;
            }
        }
    }
}

.users-page {

    h3 {
        margin-bottom: 20px;
    }

    .actions {
        margin-bottom: 20px;
    }
}

.teams-page {
    .actions {
        margin-bottom: 20px;
    }
}

#user-teams-tooltip {
    min-width: 140px;
}

.user-teams-inner {
    padding: 6px;
    text-align: left;

    p {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 12px;
    }

    .separator {
        width: 100%;
        height: 1px;
        background-color: $background-color;
        margin-top: 7px;
    }

    ul {
        list-style: none;
        padding-left: 5px;
        margin-bottom: 0px;

        li {
            font-size: 12px;
        }
    }
}

.name-spinner {
    display: flex;
}

.title-desc {
    display: flex;
    flex-direction: column;

    h3 {
        position: relative;
        top: 3px;
    }
}

.description-board {
    color: #777;
    font-size: 18px;
    font-weight: 400;
}

.tables-container {
    margin-top: 15px;
    padding: 10px 5px;

    .cancel-reorder{
        cursor: pointer;
        color: #007bff;
    }

    // //Version attached and small
    //::-webkit-scrollbar {
    //    width: 0.4em;
    //    background: transparent;
    //}
    //::-webkit-scrollbar-thumb {
    //    //background: #40a9ff;
    //    background: #88A;
    //    border-radius: 7px;
    //}

    //// Version with too much padding
    //::-webkit-scrollbar {
    //    width: 14px;
    //    height: 18px;
    //}
    //::-webkit-scrollbar-thumb {
    //    height: 6px;
    //    border: 4px solid rgba(0, 0, 0, 0);
    //    background-clip: padding-box;
    //    -webkit-border-radius: 7px;
    //    background-color: rgba(0, 0, 0, 0.15);
    //    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    //}
    //::-webkit-scrollbar-button {
    //    width: 0;
    //    height: 0;
    //    display: none;
    //}
    //::-webkit-scrollbar-corner {
    //    background-color: transparent;
    //}

    ::-webkit-scrollbar {
        width: 14px;
        height: 18px;
    }

    ::-webkit-scrollbar-thumb {
        height: 6px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 7px;
        background-color: rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }

    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    .add-elements-container{
        margin-top: 30px;
        padding-left: 20px;

        &>*{
            margin-right: 5px;
        }
        svg{
            position: relative;
            top: -3px;
        }

        .ant-row{
            margin-bottom: 15px;
            &>p{
                padding: 5px;
                margin-right: 10px;
                //color: #1890ff;
                color: #444;
                font-weight: 600;
            }
            &>button{
                margin-right: 7px;
                background-color: rgb(50, 50, 50);
                border: 1px solid rgb(50,50,50);
                color: white;
                //background: $background-color;
                //color: black;

                &:hover{
                    background: rgb(90,90,90);
                    color: white;
                }
            }
        }
    }

    .copy-component, .asset-component{
        //padding-left: 7px;
        width: 1070px;

        .actions{
            margin-top: 10px;
            margin-left: auto;
            justify-content: flex-end;
            padding-right: 0px;
            button{
                margin-left: 10px;
            }
        }
        .copy-view, .asset-view{
            position: relative;

            h1, h2{
                font-family: 'Lato';
            }
            h2{
                font-size: 24px;
            }

            .empty{
                color: gray;
                font-size: 24px;
            }
            .actions{
                position: absolute;
                top: 0px;
                right: 10px;
                margin-top: 0px;
                &.mobile{
                    display: none;
                }
            }
            table{
                width: 100%;
                margin-bottom: 1rem;
                color: #212529;
            }
            table td, table th{
                padding: .75rem;
                vertical-align: top;
                border: 1px solid #dee2e6;
            }

            .image-style-align-left{
                text-align: left;
            }
            .image-style-align-center{
                text-align: center;
            }
            .image-style-align-right{
                text-align: right;
            }

            //Assets
            .asset-title{
                font-size: 24px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: 700;
                input{
                    width: 550px !important;
                }
            }

            .upload-table-wrapper{
                width: 100% !important;

                table td, table th{
                    border: none;
                    border-top: 1px solid #dee2e6;
                }
                td, tr{
                    height: 54px;
                }
                table tr:first-child td{
                    border-top: none;
                }
                table td.align{
                    vertical-align: middle;
                    &>div{
                        //position: relative;
                    }
                }

                .spinner{
                    position: relative;
                    width: 13px;
                    height: 13px;
                    top: initial;
                    right: initial;
                    float: none;
                }
            }

            .asset-name input{
                width: 550px !important;
            }

            .delete-asset{
                color: red;
                font-size: 26px;
            }

            .download-asset{
                color: #007bff;
                font-size: 22px;
            }

        }
    }

    @media (max-width: 850px) {
        .copy-component, .asset-component{
            min-width: auto;
            width: 100%;
            padding-left: 0px;
            .copy-view{
                img{
                    max-width: 100%;
                }
                .actions.mobile{
                    padding: 0px;
                    display: block;
                }
                .actions.desktop{
                    display: none;
                }
            }

            .asset-view{
                .asset-row{
                    display: flex;
                    td{
                        width: 100%;
                    }
                    .align.d-flex{
                        width: 100px;
                    }
                }
                .asset-title{
                    input{
                        max-width: 250px !important;
                        width: 250px !important;
                    }
                }
                .actions.mobile{
                    padding: 0px;
                    display: block;
                }
            }
        }
    }

    .empty-board{
        .board-onboarding{
            display: flex;

            &>*{
                width: 30%;
                margin: 1%;
                padding: 2%;
                border: 1px solid rgba(20,20,20,0.15);
                border-radius: 2px;
                cursor: pointer;
            }

            img{
                max-width: 100%;
                height: 157px;
                margin-bottom: 8px;
            }

            h3{
                font-size: 19px;

            }
        }

        .arrows{
            min-height: 100px;
        }
    }

    @media (max-width: 600px) {
        .empty-board {
            .board-onboarding {
                flex-direction: column;

                &>*{
                    width: 98%;
                    margin: 2%;
                    padding: 4%;
                    border: 1px solid rgba(20,20,20,0.15);
                    border-radius: 2px;
                    cursor: pointer;
                }

                img{
                    max-width: 100%;
                    height: auto;
                    margin-bottom: 8px;
                }
            }
            .arrows{
                display: none;
            }
        }
    }
}


.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused{
    box-shadow: none !important;
    outline: none !important;
}
.ck-content{
    outline: none !important;
}

.threedots {
    color: #000;
    font-family: "Arial";
    text-decoration: none;
}

.threedots:after {
    content: "\2807";
    font-size: 30px;
}

.connected-users {
    margin-left: 30px;
    padding-top: 8px;
    cursor: pointer;

    p {
        font-size: 14px;
        margin-bottom: 4px;

        span {
            color: #1890ff;
            font-family: "Nunito Sans", sans-serif;
        }
    }
}

.connected-users-inner {
    padding: 6px;
    text-align: left;
    margin: 0px;

    ul {
        list-style: none;
        padding-left: 5px;
        margin: 0px;

        li {
            font-size: 12px;
            display: block;
            margin-bottom: 0px;
        }
    }
}

.dropdown a {
    text-decoration: none;
}

.table-wrapper {
    //margin-bottom: 20px;
    //padding-bottom: 50px;
    padding-bottom: 10px;
    /* overflow: auto; */
    /* TODO, we need to tackle the individual scroll of each table  */
    //overflow: inherit !important;
    position: relative;
    //padding-left: 18px;
    padding-left: 22px;

    .calendar-container {
        min-width: 680px;

        .fc-daygrid-day-number {
            color: gray;
        }

        .fc .fc-col-header-cell-cushion {
            color: black;
        }

        .fc-event-time {
            display: none;
        }
    }

    .table-loading {
        display: flex;
        margin-top: 7px;
        margin-bottom: 7px;

        .spinner-border {
            color: #1890ff;
            right: 171px;
            width: 17px;
            height: 17px;
            top: 24px;
            display: inline-block;
            margin-right: 10px;
        }

        p {
            display: inline-block;
            margin-bottom: 0px;
            position: relative;
            top: -3px;
        }
    }
}


.dragging-table{
    height: 47px !important;
    max-height: 47px !important;

    //overflow: hidden;

    &.table-type, &.asset-table-type, &.copy-type{
        margin-bottom: 5px;
        .table-wrapper{
            border: 1px solid gray;
            border-radius: 4px;
            margin-bottom: 10px;
            background-color: $background-color;
            height: 47px !important;
            max-height: 47px !important;
        }
    }

    .table-drag-handle{
        visibility: visible;
    }

    &.table-type{
        .table-wrapper{
            //padding-bottom: 0px;
            //margin-bottom: 12px;
            //border: 2px solid #333;
            .draggable-list-rows, .table-footer, .add-row-container, .columns-wrapper, .ant-dropdown-trigger, .sum-cell{
                display: none;
            }

            .resizable-column-header{
                margin-left: 10px;
            }
        }
    }

    &.asset-table-type{
        .table-wrapper{
            padding-top: 3px;
            //padding-bottom: 0px;
            //border: 2px solid #333;
            h3{
                font-size: 24px;
            }
            .upload-table-wrapper, .actions{
                display: none;
            }
            .asset-component{
                margin-left: 10px;
            }
        }
    }

    &.copy-type{
        .table-wrapper{
            padding: 9px 25px;
            h3{
                padding-bottom: 0px;
                font-size: 24px;
                margin-left: 10px;
            }
            //border: 2px solid #333;
            .copy-view>div>*{
                display: none;
            }

            //.copy-view>div>*:first-child{
            //    display: block;
            //    margin-bottom: 0px;
            //}
        }
    }
}

.draggable {
    top: auto !important;
    left: auto !important;
}

.row-modal {
    width: 800px !important;

    .ant-modal-body {
        background-color: #f4f5f7;
    }

    h3 {
        margin-bottom: 0;
        font-size: 22px;
    }

    .title-section {
        .edit-row-name {
            input {
                width: 100%;
                min-width: 500px;
            }
        }
    }

    h4 {
        margin-bottom: 0;
        font-size: 19px;
        margin-left: 2px;
    }

    .ant-modal-header {
        display: none;
    }

    .row-modal-body {
        display: flex;
        justify-content: space-between;
    }

    .main-section {
        width: 70%;
        flex-basis: 75%;
    }

    .right-options {
        width: 25%;
        flex-basis: 22%;
    }

    .text-cells, .date-cells, .people-cells, .status-cells {
        margin-bottom: 20px;

        .label-text-cell, .label-date-cell {
            margin-bottom: 2px;
            margin-left: 2px;
        }

        .cell-entry {
            border: 1px solid #ccc;
            //min-height: 45px;
            height: auto;
        }

        .row-name {
            & > div:first-child {
                text-align: left;
                min-width: 100%;
                height: 100%;
                min-height: 15px;
            }

            & > input {
                max-width: 100%;
                height: 100%;
                //min-height: 15px;
            }
        }

        .text-cell, .date-cell, .people-cell, .state-cell {
            //margin-top: 5px;
            margin-bottom: 5px;
        }

        .react-datepicker-wrapper input {
            margin-top: 3px;
            background: $background-color;
        }

        .people-button {
            margin-top: 6px;
            background: transparent;
            border: none;
            padding-bottom: 6px;
        }
    }

    .text-cell .cell-entry {
        border: none;
        border-radius: 3px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }

    .people-cells {
        margin-bottom: 0px;
    }

    .people-cell, .status-cell {
        background: transparent;
        border: none !important;
    }

    .datetime-cell {
        background: transparent;
        border: none !important;

        input {
            background: transparent;
        }
    }

    .message-card {
        border: none;
        margin-bottom: 6px;

        .message-card-header {
            background-color: transparent;
        }

        .card-date {
            position: relative;
            top: 2px;
        }
    }

    .messages-container {
        margin-top: 9px;

        .message-card-content {
            background-color: $background-color;
            border-radius: 3px;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);

            p {
                padding: 10px;
                margin-bottom: 0;
            }
        }
    }
}

.users-table-container {
    position: relative;

    @media (max-width: 850px) {
        .users-table-wrapper {
            overflow-x: scroll;
        }
    }

    .spinner {
        top: -46px;
        position: absolute;
        right: 4px;
        width: 26px;
        height: 26px;
        color: #1e88e5;
    }

    .user-table {
        color: initial;
        margin-top: 10px;

        p {
            margin-bottom: 0px;
        }

        td {
            vertical-align: middle;
        }

        thead th {
            border-bottom: 0px;
            border-top: 0px;
            color: gray;
            font-weight: 400;
        }

        .user-name-row {
            display: flex;

            .user-thumb {
                margin-right: 15px;
                width: 38px;
                height: 38px;
            }

            .vertical-div {
                display: flex;
                flex-direction: column;
            }

            .user-initials {
                padding-top: 8px;
                font-size: 14px;
            }

            .name {
                font-weight: 400;
                margin-bottom: 0px;
            }

            .email {
                color: darkgray;
                font-size: 12px;
                position: relative;
                top: -1px;
            }
        }

        .num {
            color: #1e88e5;
            opacity: 0.9;
        }
    }
}

.teams-table-container {
    position: relative;

    .spinner {
        top: -46px;
        position: absolute;
        right: 4px;
        width: 26px;
        height: 26px;
        color: #1e88e5;
    }

    .teams-table {
        color: initial;
        margin-top: 10px;

        p {
            margin-bottom: 0px;
        }

        td {
            vertical-align: middle;
        }

        thead th {
            border-bottom: 0px;
            border-top: 0px;
            color: gray;
            font-weight: 400;
        }

        .team-name-row {
            display: flex;

            .user-thumb {
                margin-right: 15px;
                width: 38px;
                height: 38px;
            }

            .vertical-div {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .user-initials {
                padding-top: 8px;
                font-size: 14px;
            }

            .name {
                font-weight: 400;
                margin-bottom: 0px;
            }
        }

        .row-actions {
            display: flex;

            .edit-team-button {
                position: relative;
                font-size: 17px;
                top: -3px;
            }

            .delete-team-button {
                margin-left: 10px;
                position: relative;
                font-size: 15px;
                top: 2px;
                height: -moz-fit-content;
                height: fit-content;
            }
        }

        .num {
            color: #1e88e5;
            opacity: 0.9;
        }
    }
}

.boards-table-container {
    position: relative;

    .spinner {
        top: -46px;
        position: absolute;
        right: 4px;
        width: 26px;
        height: 26px;
        color: #1e88e5;
    }

    .category-select{
        width: 50%;

        .select__control{
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            margin: 0;
            //font-variant: tabular-nums;
            list-style: none;
            //-webkit-font-feature-settings: 'tnum';
            //font-feature-settings: 'tnum', "tnum";
            position: relative;
            //display: inline-block;
            width: 100%;
            min-width: 0;
            padding: 4px 11px;
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            line-height: 1.5715;
            background-color: #fff;
            background-image: none;
            //border: 1px solid #d9d9d9;
            border-radius: 2px;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            max-height: 32px;
            padding-top: 0px;
            padding-bottom: 0px;
            margin-bottom: 0px;
            margin-top: 0px;
            min-height: 32px;
            max-height: 32px;
            padding-right: 0;

            .select__value-container{
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 13px;
                padding-left: 0px;
                position: relative;
                top: -3px;
                .select__placeholder{
                    top: 18px;
                    color: rgba(10,10,10,0.35);
                }
            }

            .select__multi-value>div:not(.select__multi-value__remove){
                position: relative;
                top: -1px;
            }
        }
    }

    .search-grid{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        left: -10px;

        .search-item{
            margin: 5px 1%;
            padding: 12px;
            cursor: pointer;
            position: relative;
            border: 1px solid rgba(4,4,4,0.1);
            border-radius: 1px;
            width: 18%;

            h4{
                margin-bottom: 3px;
                font-size: 16.9px;
                color: #1890ff;
                pointer-events: none;
            }

            p{
                font-size: 13px;
                pointer-events: none;
            }

            .search-group{
                position: absolute;
                top: 0px;
                right: 5px;
                display:flex;
                visibility: hidden;
                .remove-search-cta{
                    margin-left: 7px;
                }
            }

            &:hover .search-group{
                visibility: visible;
            }

            .categories{
                display: flex;
                flex-wrap: wrap;
                position: relative;
                left: -2px;
                pointer-events: none;

                .category{
                    background-color: #e6e6e6;
                    border-radius: 2px;
                    display: flex;
                    margin: 2px;
                    min-width: 0;
                    box-sizing: border-box;

                    .label{
                        border-radius: 2px;
                        color: #333333;
                        font-size: 85%;
                        overflow: hidden;
                        padding: 3px;
                        padding-left: 6px;
                        padding-right: 6px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .boards-table-container {
        .search-grid{
            left: 0px;

            .search-item{
                width: 31%;
            }
        }

    }
}


.boards-list-page, .dashboard-page {

    .search-actions {
        display: flex;
        margin-top: 20px;

        .filters-bar{
            display: flex;
        }

        & .ant-select {
            width: 170px;
            margin-right: 15px;
        }
    }
}

@media (max-width: 768px) {
    .boards-list-page, .dashboard-page {

        .ant-select{
            width: 50% !important;
        }

        .search-actions {
            flex-direction: column;
        }

        .filters-bar{
            margin-top: 10px;
            flex-wrap: wrap;
            margin-bottom: 5px;
        }

        .save-search{
            margin-top: 10px;
        }
    }
}


.assets-table-container, .upload-table-wrapper {
    position: relative;

    @media (max-width: 850px) {
        .assets-table-wrapper {
            overflow-x: scroll;
        }
    }

    .assets-actions{
        margin-bottom: 20px;
        button{
            margin-right: 10px;
        }
        .right-actions{
            float:right;
        }

        @media (max-width: 850px) {
            .right-actions{
                float: none;
                margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
                & > button{
                  margin-right: 10px;
                  margin-bottom: 10px;
                }
            }
        }
    }

    .asset-name-row{
        display: flex;

        &>.name{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .asset-icon{
            //height: 30px;
            height: 25px;
            margin-right: 10px;
            text-align: center;
            width: 40px;

            &>*{
              height: 100%;
              max-width: 100%;
            }

            &>img{
                max-width: 30px;
                max-height: 100%;
                height: initial;
            }
        }
    }

    .asset-row{
        cursor: pointer;

        &:hover{
            background-color: rgba(50,50,150,0.06);
        }
    }

    .spinner {
        top: -46px;
        position: absolute;
        right: 4px;
        width: 26px;
        height: 26px;
        color: #1e88e5;
    }

    .asset-table {
        color: initial;
        margin-top: 10px;

        p {
            margin-bottom: 0px;
        }

        td {
            vertical-align: middle;
        }

        thead th {
            border-bottom: 0px;
            border-top: 0px;
            color: gray;
            font-weight: 400;
        }

        .num {
            color: #1e88e5;
            opacity: 0.9;
        }
    }
}

.asset-drawer{

    @media (max-width: 850px) {
        .ant-drawer-content-wrapper{
            width: 100% !important;
        }
    }

    .ant-drawer-body{
        padding-top: 51px;

        .asset-preview{
            width: 100%;
            .asset-icon{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                min-height: 200px;
                max-height: 300px;
                height: 100%;
                overflow: hidden;
                img{
                    width: 100%;
                }
                svg{
                    width: 120px;
                    max-height: 150px;
                }
            }
        }

        .asset-name{
            margin-top: 10px;
            color: black;
            font-size: 17px;
        }

        .metadata{
            margin-top: 10px;
            .entry{
                display: flex;
                justify-content: space-between;
                p{
                    margin-bottom: 8px;
                    &.value{
                        color: #111;
                    }
                }
                .value a{
                    margin-top: 0px;
                }
            }
            a{
                margin-left: auto;
                margin-right: auto;
                display: inline-block;
                margin-top: 10px;
            }
        }
    }
}

.asset-upload-modal, .upload-table-wrapper{
    width: 710px !important;
    .upload-dropzone{
        border: 1px dashed #333;
        p{
            margin-bottom: 0;
            text-align: center;
            padding: 30px;
            font-size: 16px;
        }
    }

    .asset-table{
        margin-top: 10px;
        th{
            border-top: none !important;
        }
        td,th{
            padding: .55rem;
        }
        .done{
            color: #1e88e5;
        }
        .error{
            color: #ff4d4f;
        }
    }
    .tip{
        margin-bottom: 0px;
        font-size: 13px;
        margin-left: 7px;
    }

    .spinner-animation{
        color: #007bff;
        width: 1rem;
        height: 1rem;
        font-size: 10px;
        position: relative;
        top: 4px;
        margin-right: 10px;
        margin-left: 10px;
        float: left;
    }
}
@media (max-width: 850px) {
    .upload-table-wrapper{
        width: 100% !important;
    }
}

.boards-list-page, .dashboard-page {
    color: initial;
    // margin-top: 10px;
    min-width: 100%;

    .actions {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 0px;
    }

    td {
        vertical-align: middle;
    }

    thead th {
        border-bottom: 0px;
        border-top: 0px;
        color: gray;
        font-weight: 400;
    }

    .disk{
        span{
            font-size: 12px;
        }
        .anticon-info-circle{
            position: relative;
            top: -3px;
            left: 2px;
        }
        .top, .bottom{
            display:block;
        }
        .disk-wrapper{
            position: relative;
            text-align: center;
            .percentage{
                text-align:center;
                position: relative;
                display: inline-block;
            }
            .values{
                position: absolute;
                right: -55px;
                top: 16px;
                text-align: left;
            }
        }
    }

    .board-name-row {
        display: flex;

        .board-thumb {
            margin-right: 15px;
            width: 38px;
            height: 38px;
        }

        .vertical-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .user-initials {
            padding-top: 8px;
            font-size: 14px;
        }

        .name {
            font-weight: 400;
            margin-bottom: 0px;
            color: black;

            a {
                color: black;
            }
        }

        .visibility {
            color: darkgray;
            font-size: 12px;
            position: relative;
            top: -1px;
            text-transform: capitalize;
        }

        .favorite {
            position: relative;
            top: -2px;
            margin-left: 6px;
        }

        .unfavorite-cta {
            color: #40a9ff;
        }
    }

    .row-actions {
        display: flex;
    }

    .num {
        color: #1e88e5;
        opacity: 0.9;
    }

    .categories{
        .d-flex{
            .category{
                background-color: hsl(0,0%,90%);
                border-radius: 2px;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                margin: 2px;
                min-width: 0;
                box-sizing: border-box;
                .label{
                    border-radius: 2px;
                    color: hsl(0,0%,20%);
                    font-size: 85%;
                    overflow: hidden;
                    padding: 3px;
                    padding-left: 6px;
                    padding-right: 6px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    box-sizing: border-box;
                }
            }
        }
    }

    .users-and-teams {
        margin-left: 0;

        ul {
            margin-bottom: 0px;
        }
    }
}

.dashboard-page {

    .metrics {
        margin-top: 30px;
        margin-bottom: 30px;
        color: rgba(0, 0, 0, 0.65);
    }

    @media (max-width: 850px) {
        .metrics{
            display: flex;
            flex-wrap: wrap;
            & > div {
                width: 50%;
            }
        }
    }
}

.edit-profile-page-container {
    height: 100%;
    padding-top: 5%;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;

    .edit-profile-page {
        width: 100%;
        display: flex;
        align-items: flex-start;

        h3 {
            font-size: 20px;
            font-family: "Nunito Sans", sans-serif;
            font-weight: 100;
        }

        .separator {
            margin-top: 10px;
            margin-bottom: 20px;
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            height: 1px;
            background-color: #ddd;
            display: block;
            z-index: 3;
        }

        .image-container {
            display: inline-block;
            width: 35%;
            padding-top: 40px;
            text-align: center;

            .actions {
                margin-top: 40px;

                button {
                    display: block;
                    margin: auto;
                    width: 170px;
                    margin-top: 15px;
                }
            }

            .name-surname {
                margin-top: 10px;
                text-align: center;
                font-family: "Nunito Sans", sans-serif;

                .name, .surname {
                    display: inline-block;
                    margin-bottom: 0px;
                    font-size: 20px;
                    font-weight: 400;
                    font-family: "Nunito Sans", sans-serif;
                }

                .title {
                    font-size: 16px;
                    //color: #FF6900;
                    color: #999;
                    font-weight: 400;
                }
            }

            .metrics {
                display: flex;
                justify-content: center;
                margin-top: 40px;

                .metric {
                    width: initial;

                    .name {

                    }

                    .value {
                        font-size: 28px;
                    }
                }

                .separator {
                    background-color: #ddd;
                    height: 100%;
                    width: 1px;
                    margin-right: 30px;
                    margin-left: 30px;
                    display: block;
                    margin-bottom: 0;
                    margin-top: 0;
                    height: 62px;
                }
            }

        }

        .info-container {
            display: inline-block;
            width: 65%;
            padding: 20px 50px;
            border-left: 1px solid #ddd;
        }

        .inline-fields {
            display: flex;
            justify-content: space-between;

            .form-group {
                width: 45%;
                flex-basis: 45%;
                margin-bottom: 0;
            }
        }

        button.submit {
            margin-top: 15px;
        }
    }
}

@media (max-width: 768px) {
    .edit-profile-page-container {
        padding-bottom: 30px !important;

        .edit-profile-page {
            flex-direction: column;

            .image-container {
                width: 100%;
            }

            .info-container {
                width: 100%;
                border-left: none;
                margin-top: 20px;
                padding: 20px 4%;
            }
        }
    }
    .ant-layout.ant-layout-has-sider > .ant-layout-content {
        background: $background-color;
    }
}

.admin-page-container {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;

    .separator {
        margin-top: 30px;
        margin-bottom: 20px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        height: 1px;
        background-color: #ddd;
        display: block;
        z-index: 3;
    }

    .address {
        margin-bottom: 8px;
    }

    .inline-fields {
        //margin-top: 12px;
    }

    .actions {
        margin-top: 20px;
        text-align: right;
    }

    .company-info {
        //margin-top: 30px;
    }

    h5 {
        display: block;
    }

    .info-container {
        width: 100%;
    }

    .admin-users {
        padding-bottom: 240px;
    }

    .delete-account-page {
        .actions {
            text-align: left;
        }
    }
}

.inline-fields {
    display: flex;
    justify-content: space-between;

    .form-group {
        width: 45%;
        flex-basis: 45%;
        margin-bottom: 0;
    }
}

/* .table-wrapper::-webkit-scrollbar {
    display: none;
}
  
.table-wrapper{
    -ms-overflow-style: none;
} */

.page-inner {
    padding: 20px;
    background-color: $background-color;
}

.page-padding {
    padding: 2%;
    padding-top: 14px;
}

.boards-page-top {
    height: 100%;
    padding: 0%;
    //overflow-x: auto;
    overflow-x: hidden;

    @media (max-width: 850px) {
        .main-container{
            max-width: initial;
            width: 100%;
        }
    }
}

.table-header {
    display: flex;

    .react-resizable {
        flex-grow: 0;
        flex-shrink: 0;
        //top: -1px;
    }
}

.table-actions {
    //min-width: 160px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    height: 45px;

    h4 {
        margin-bottom: 0;
    }

    .delete-table {
        color: #ff4d4f;
    }
}


div[contenteditable="true"]:hover {
    border: 1px dashed #aaa;
}

.hide {
    display: none;
}

.title-table {
    border: 1px solid white;
    padding: 6px;
}

.title-table:hover {
    border: 1px dashed #aaa;
}

.title-table > input {
    padding: 0px !important;
}

.add-column-action {
    background: none;
    color: #007bff;
    border: none;
    outline: none;
    font-size: 18px;
    height: 100%;
    min-width: 50px;
}

.add-column-action:focus {
    outline: none;
}

.add-column-action:hover {
    color: black;
}

.add-row-container{
    padding: 7px;
}

.add-row-container > div,
.add-row-container > div a {
    height: 100%;
    display: block;
}

.add-row-container svg {
    padding-right: 7px;
    color: #007bff;
    font-size: 24px;
}

.column-header {
    min-width: 160px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $background-color;
}

.column-header p {
    margin-bottom: 0px;
}

.column-drag-handler {
    width: 30px;
    display: block;
    font-size: 13px;
    color: gray;
    visibility: hidden;
}

.column-name {
    max-height: 44px;
    border: 1px solid #fafafa;
}

.column-name:hover {
    border: 1px dashed #aaa;
}

.column-name > input {
    padding: 0px !important;
    max-width: 80px;
}

.edit-column-action {
    border: none;
    background: none;
    color: #aaa;
    font-size: 18px;
    position: relative;
    right: 5px;
    visibility: hidden;
}

.column-header:hover .edit-column-action{
    visibility: visible;
}

.edit-column-action:focus {
    outline: none;
}

.edit-column-dropdown {
    padding: 7px;
    .delete-column {
        color: #ff4d4f;
    }
}

//.column-header:hover {
//    background-color: #fafafa;
//}

.column-header:hover .edit-column-dropdown,
.column-header:hover .column-drag-handler {
    visibility: visible;
}

.edit-column-name .column-name [editext="view"] {
    white-space: nowrap;
}

.add-row-action {
    margin-top: 10px;
}

.add-row-action input {
    min-width: 400px;
    max-height: 32px;
}

@media (max-width: 768px) {

    .column-header .edit-column-dropdown,
    .column-header .column-drag-handler {
        visibility: visible;
    }

    .add-row-action-input {
        margin-bottom: 0px;
        width: 100%;
    }
}

.row-entry {
    display: flex;
    outline: none;
}

.row-name-container {
    //width: 160px;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    height: 45px;
    padding: 11px;
    //flex-basis: 160px;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: $background-color;
    position: relative;

    .edit-row-name {
        flex-grow: 2;
        position: relative;
        top: -1px;
        overflow: hidden;
        height: 24px;

        .row-name {
            width: 100%;
            min-width: 100%;
            border: 1px solid transparent;

            input {
                width: 100%;
                min-width: 100%;
                max-width: 100%;
            }
        }

        .row-name:hover {
            background-color: #fafafa;
            border: 1px dashed #aaa !important;
        }
    }

    .message-button {
        position: relative;
        top: 2px;
        font-size: 16px;
        //position: absolute;
        //top: 13px;
        //right: 14px;
        margin-left: 10px;
        //padding-left: 10px;
    }
}

.draggable-list-rows > div > div:last-child .row-name-container {
    border-bottom: 1px solid #ccc;
}

.row-drag-handle {
    color: #999;
    font-size: 17px;
    position: relative;
    top: 5px;
    cursor: pointer;
}

/* Rows */


.row-name > input {
    padding: 0px !important;
    max-width: 80px;
}
.page-inner
.edit-row-action {
    border: none;
    background: none;
    color: #aaa;
    font-size: 18px;
}

.edit-row-action:focus {
    outline: none;
}

.edit-row-select {
    visibility: hidden;
    position: absolute;
    //left: -30px;
    left: -23px;
    top: 10px;
}

@media (max-width: 768px) {
    .edit-row-select {
        visibility: visible;
    }
}

.row-name-container:hover {
    background-color: #fafafa;
}

.row-name-container:hover .edit-row-select,
.row-entry.selected .edit-row-select,
.selected-table .edit-row-select {
    visibility: visible;
}

.row-entry.selected {
    .row-name-container {
        background-color: #fafafa;
    }

    .cells-container .cell-entry {
        background-color: #fafafa;
    }

    .react-datepicker-wrapper input {
        background-color: #fafafa;
    }
}

.subtasks {
    padding: 18px;
}

.subtask-button {
    margin-left: 6px;
    padding-left: 6px;
    border: 0px;
    background-color: transparent;
    padding-top: 0px;
    position: relative;
    top: -4px;
    font-size: 17px;
    padding-right: 0px;
    outline: none !important;

    svg {
        outline: none;
    }

    &:active {
        outline: none;
    }

    &.unfold {
        color: #40a9ff;
    }

    .num-subtasks {
        z-index: auto;
        width: 6px;
        height: 6px;
        background: #ff4d4f;
        border-radius: 100%;
        box-shadow: 0 0 0 1px #fff;
        position: absolute;
        top: 1px;
        right: -3px;
    }
}

.show-subtask {
    .row-name-container, .cell-entry {
        border-bottom: 1px solid #ccc;
    }
}

.row-name-container .row-name [editext="view"] {
    white-space: nowrap;
}

.bulk-actions-bar {
    margin-right: auto;
    margin-left: auto;
    right: 0;
    left: 0;
    bottom: 20px;
    position: fixed;
    width: 384px;
    height: 70px;
    //border: 1px solid #aaa;
    background-color: $background-color;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border-radius: 3px;
    box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25), 0 0 0 1px rgba(9, 30, 66, 0.08);
    box-sizing: border-box;
    outline: 0;
    overflow: hidden;
    z-index: 9;
    padding: 11px 19px;
    display: flex;

    .bulk-button {
        display: flex;
        flex-direction: column;
        color: #555;
        text-align: center;
        font-size: 12px;

        .anticon {
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 3px;
            font-size: 18px;
        }
    }

    .grower {
        flex-grow: 3;
    }

    .ant-btn > .anticon + span, .ant-btn > span + .anticon {
        margin-left: 0px
    }

    .duplicate-rows-cta {

    }

    .delete-rows-cta {

    }

    .cancel {

    }
}

/* Cell */

.cells-container {
    display: flex;
    text-align: center;
}

.cell-entry {
    //width: 160px;
    border-top: 1px solid #ccc;
    border-left: none;
    border-right: 1px solid #ccc;
    /* display: flex;
    justify-content: space-between; */
    height: 45px;
    padding: 9px;
    text-align: center;
    background-color: $background-color;

    .ant-picker{
        width: 100%;
        height: 40px;
        border: none;
        padding: 21px 29px;
        background: $background-color;
    }
}

.cell-entry:hover {
    background-color: #fafafa;
}

.draggable-list-rows > div > div:last-child .row-entry:last-child .cell-entry {
    border-bottom: 1px solid #ccc;
}

.row-entry .cell-entry .row-name,
.row-entry .cell-entry .row-name > div {
    /* min-width: 50px; */
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    top: 1px;
}

.row-entry .cell-entry .row-name input {
    display: block;
    max-width: 100%;
}

.cells-container .edit-entry {
    width: 100%;
    height: 100%;
}

/* Status Cell */

.status-cell{
    padding: 0px;
}

.status-dropdown {
    width: 250px;
    background: $background-color;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}

.status-toggle {
    outline: none;
    /* border: 1px solid #000; */
    border-radius: 0px;
    width: 100%;
    height: 100%;
    color: white;
    border: none;
    background: $background-color;
}

.status-toggle:focus {
    outline: none;
    box-shadow: none;
}

.status-toggle:after {
    display: none;
}

.status-dropdown .dropdown-menu.show::before,
.status-dropdown .dropdown-menu.show::after {
    content: " ";
    position: absolute;
    border: solid transparent;
    bottom: 100%;
    border-color: rgba(225, 225, 225, 0);
    pointer-events: none;
    height: 0;
    width: 0;
}

.status-dropdown .dropdown-menu.show::before {
    right: 50%;
    transform: translateX(50%);
    border-bottom-color: #c4c4c4;
    border-width: 10px;
}

.status-dropdown .dropdown-menu.show::after {
    right: calc(50%);
    transform: translateX(50%);
    border-bottom-color: white;
    border-width: 8px;
}

.status-dropdown .dropdown-menu.show {
    width: 250px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    transform: translate3d(-42px, 48px, 0px) !important;
}

.status-dropdown > .dropdown {
    height: 100%;
    /* position: initial; */
}

.status-dropdown .status-options,
.status-dropdown .status-edit {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    cursor: pointer;
}

.ant-dropdown{
    animation-duration: 0ms !important;
}

.status-dropdown .status-options .status,
.status-dropdown .status-edit .status-edit-input {
    width: 48%;
    margin: 1%;
    font-size: 14px;
    padding: 7px;
    border: none;
    color: white;
    min-height: 35px;
}

.status-dropdown .edit-status {
    text-align: center;
    border-top: 1px solid #ccc;
    padding-top: 5px;
    font-size: 14px;
    height: 37px;
}

.status-dropdown .edit-status button {
    border: none;
    background: none;
    color: #4285f4;
}

.status-dropdown .edit-status button:focus {
    outline: none;
}

.status-dropdown .band {
    height: 100%;
    width: 13px;
    margin-right: 7px;
    /* border: 1px solid #ccc; */
}

.status-dropdown .status-edit .status-edit-input {
    color: black;
    display: flex;
    padding: 0px;
}

.status-dropdown .status-edit input {
    border: none;
    max-width: 90px;
}

.create-table-cta svg {
    margin-right: 5px;
    position: relative;
    top: -2px;
    font-size: 19px;
}


/* use css to set position of modal */
.vertical-center-modal {
    text-align: center;
    white-space: nowrap;
}

.vertical-center-modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
}

.vertical-center-modal .ant-modal {
    display: inline-block;
    vertical-align: middle;
    top: 0;
    text-align: left;
}

.reconnecting-toast {
    position: fixed;
    bottom: 12px;
    //margin-left: auto;
    //margin-right: auto;
    //padding: 10px;
    right: 0;
    //left: 0;
    z-index: 10;
    //color: black;
    //max-width: 160px;
    //border: 1px solid gray;
    //-webkit-box-shadow: 0px 0px 37px -13px rgba(0, 0, 0, 0.52);
    //-moz-box-shadow: 0px 0px 37px -13px rgba(0, 0, 0, 0.52);
    //box-shadow: 0px 0px 37px -13px rgba(0, 0, 0, 0.52);
    //display: flex;
    //justify-content: center;
    //background: white;
}

.reconnecting-toast > .spinner-animation {
    color: #007bff;
    width: 1rem;
    height: 1rem;
    font-size: 10px;
    position: relative;
    top: 4px;
    margin-right: 10px;
}

.reconnecting-toast > * {
    margin-bottom: 0px;
}

/* date cells */

.datetime-cell {
    padding: 0px;
    text-align: center;
    max-width: 100%;
}

.react-datepicker-wrapper,
.react-datepicker-wrapper > div {
    height: 100%;
}

.react-datepicker-wrapper input {
    border: none;
    text-align: center;
    width: 100%;
    max-width: 100%;
    height: 100%;
}

/* number cells */

.empty-cell {
    /* height: 45px; */
    /* padding: 9px; */
    text-align: center;
    //width: 160px;
    flex-shrink: 0;
}

.sum-cell {
    height: 45px;
    padding: 9px;
    text-align: center;
    //width: 160px;
    border-top: none;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    position: relative;
    left: -1px;

    .label {
        color: #007bff;
        font-size: 10px;
        position: absolute;
        left: 10px;
        top: 15px;
    }

    .value {
        font-weight: 600;
        font-size: 15px;
    }

}

/* My Profile */

.upload-profile {
    text-align: center;

    & > label {
        cursor: pointer;
    }
}

.upload-profile svg {
    font-size: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.upload-profile .profile-image {
    max-width: 120px;
    height: 120px;
    border-radius: 100%;
}

.crop-profile-image-modal .crop-container {
    min-height: 400px;
}

.crop-profile-image-modal .controls {
    background-color: $background-color;
    /* border: 1px solid red; */
    z-index: 2;
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
    padding: 20px;
}

.crop-profile-image-modal .modal-body {
    padding: 0px;
}

/* Common */

.user-thumb {
    border-radius: 100%;
    width: 33px;
    height: 33px;
    margin-left: 5px;
    margin-bottom: 0px;
}

.user-initials {
    // background-color: green;
    color: white;
    padding-top: 4px;
    text-transform: capitalize;
    font-weight: 500;
    text-align: center;
    max-width: 100%;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    font-size: 13px;
}

.profile-image-thumb {
    max-width: 100%;
    border-radius: 100%;
}

/* people cells */

.people-cell {
    padding: 0px;
}

.people-cell .dropdown {
    height: 100%;
}

.people-cell .status-toggle {
    padding: 0px;
}

.people-button {
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;

    &>.dropdown{
        height: 45px;
    }

    & > div{
        display: inline-block;
        align-self: center;
    }

    .add-people-cta {
        position: absolute;
        left: 6px;
        top: 14px;
        color: darkgray;
    }

    .remove-people-cta {
        position: absolute;
        right: 2px;
        font-size: 18px;
        width: 21px;
        color: darkgray;
        top: 7px;
    }

    .remove-people-cta:hover {
        color: black;
    }

    .user-thumb {
        width: 28px;
        height: 28px;
        margin-right: 5px;
    }

    .no-user-icon {
        color: dimgray;
        font-size: 28px;
        align-self: center;
    }

}

.user-initials {
    margin-bottom: 0px;
    padding-bottom: 0px;
    // padding-top: 1px;
}


.people-dropdown{
    width: 250px;
    background: $background-color;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    width: 250px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    //transform: translate3d(-42px, 48px, 0px) !important;

    & > .dropdown {
        height: 100%;
        /* position: initial; */
    }

    .current-users {
        //padding: 4px 10px;
        display: flex;
        flex-wrap: wrap;

        .user-card {
            padding: 4px;
            padding-right: 0px;
            margin-bottom: 0px;
            margin-top: 4px;
            margin-left: 4px;
            margin-right: 1px;
        }
    }

    .remove-user-cta {
        cursor: pointer;
        margin: 0px;
        border-top-right-radius: 100%;
        border-bottom-right-radius: 100%;
        background-color: #dedede;
        width: 23px;
        position: relative;
        height: 24px;
        font-size: 14px;

        svg{
            position: absolute;
            top: 5px;
            right: 5px;
        }

        &:hover{
            color: darkgray;
        }
    }


    .user-card .user-thumb {
        margin: 0px;
        border-radius: 100% 0px 0px 100% !important;
        background-color: #dedede;
        border-radius: initial;
        overflow: auto;
        width: 24px;
        height: 24px;
        position: relative;
        display: block !important;
    }

    .user-card .profile-image-thumb {
        position: absolute;
    }

    .save-people-actions {
        text-align: center;
        border-top: 1px solid #ccc;
        padding-top: 5px;
        font-size: 14px;
        height: 37px;

        button{
            border: none;
            background: none;
            color: #4285f4;
            &:focus{
                outline: none;
            }
        }
    }

    .list-results {
        border-top: 1px solid #ccc;
        padding: 5px 8px;
        max-height: 220px;
        //overflow-y: scroll;
        overflow-y: auto;

        &>p{
            margin-bottom: 0px;
            padding: 5px;
            color: darkgray;
        }

        .user-initials {
            width: 28px;
            height: 28px;
        }

        .user-thumb{
            padding-top: 3px;
        }
    }
}

.plus-button {
    border-radius: 100%;
    width: 28px;
    height: 28px;
    color: white;
    background-color: darkslategray;
    margin-left: 5px;
    font-weight: 600;
}

.user-card {
    /* background-color: #dedede; */
    display: inline-block;
    margin: 4px;
}

.user-card-inner {
    display: flex;
    align-items: center;
}

.user-card-inner > div {
    height: 24px;
}

.user-card-inner p {
    margin-bottom: 0px;
    color: #444;
    padding-left: 5px;
    // font-size: 14px;
    background-color: #dedede;
    // padding-top: 0px;
    height: 24px;
    font-size: 12px;
    padding-top: 3px;
}



.user-card-inner p.user-initials {
    // background-color: green;
    padding-left: 0px;
    text-align: center;
    color: white;
}

.user-search-field {
    position: relative;
    padding: 5px;
}

.user-search-field input {
    width: 100%;
    border: none;
    padding: 1px 10px;
    margin-top: 2px;
    margin-bottom: 1px;
    color: #333;
    outline: none;
}

.user-search-field input:active {
    outline: none;
}

.user-search-field .user-field-spinner {
    color: #007bff;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 6px;
    right: 9px;
}


.user-list-item {
    width: 100%;
    cursor: pointer;
    margin: 3px 0px;
    padding: 4px;
    border-radius: 3px;
}

.user-list-item:hover {
    background-color: #ececec;
}

.user-list-item-inner {
    display: flex;
    align-items: center;
}

.user-list-item-inner > p {
    margin-bottom: 0px;
    margin-left: 5px;
}

body > .nav-item {
    z-index: 11;
}

//.table-wrapper:hover .table-drag-handle {
//    visibility: visible;
//}

.table-drag-handle {
    position: absolute;
    //left: -2px;
    left: 7px;
    top: 13px;
    color: #444;
    font-size: 18px;
    cursor: pointer;
    visibility: hidden;
}

.columns-wrapper {
    display: flex;
}

.row-name-container:hover .message-button {
    color: #1890ff;
}

.messages-drawer-spinner, .logs-drawer-spinner {
    text-align: center;
    float: none !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
    color: #1890ff !important;
    margin-top: 20px;
}

/* Message Card */

.message-card {
    border: 1px solid #f0f0f0;
    margin-bottom: 21px;
    border-radius: 2px;
}

.message-card-header {
    padding: 7px 4px;
    background-color: #fafafa;
    display: flex;
    justify-content: space-between;
}

.message-card-header > div > div {
    display: flex;
}

.message-card-header .card-date {
    margin-bottom: 0px;
    margin-right: 5px;
}

.message-card-header .user-thumb {
    width: 26px;
    height: 26px;
    margin-right: 4px;
}

.message-card-header .user-initials {
    margin-bottom: 0px;
    padding-top: 2px;
}

.message-card-header .user-item p {
    margin-bottom: 0px;
    position: relative;
    top: 1px;
}

.new-message-container {
    margin-bottom: 21px;
    text-align: right;
}

.new-message-container .ck-editor {
    margin-bottom: 10px;
}

.new-message-container > button {
    margin-top: 10px;
}

// Log drawer

.log-card {
    margin-bottom: 10px;

    .card-date {
        margin-bottom: 0px;

        strong {
            color: #292929;
        }
    }

    .message-card-content {
        padding: 2px 4px;

        p {
            margin-bottom: 0px;
            color: #222;
            font-size: 13px;
        }
    }
}

.message-drawer {
    .message-card-content {
        p {
            padding: 10px;
            margin-bottom: 0;
        }
    }

    .ant-drawer-close {
        display: none;
    }
}

@media (max-width: 768px) {
    .message-drawer {
        .ant-drawer-content-wrapper {
            width: 100% !important;
        }

        .ant-drawer-close {
            display: block;
        }
    }
}

// Resizable styles for resize columns
.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-position: bottom right;
    padding: 0 3px 3px 0;
}

.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}

.react-resizable-handle-e {
    width: 5px;
    background-image: none;
    right: 0px;
    height: 100%;
    top: 0px;
    visibility: hidden;
    padding: 0px;
    margin: 0px;
}

.resizable-column-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.resizable-column-header:hover .react-resizable-handle-e {
    background-color: #f1f1f1;
    visibility: visible;
}

.resizable-column-header .react-resizable-handle-e:hover {
    background-color: #40a9ff;
    visibility: visible;
}

.select-board-table {
    margin-top: 20px;

    .board-name-row {
        display: flex;
        cursor: pointer;

        &:hover {
            background-color: #fafafa;
        }

        .visibility {
            position: relative;
            top: -2px;
            color: #555;
            text-transform: capitalize;
            font-size: 12px;
        }
    }

    .board-thumb {
        width: 36px;
        height: 36px;
        margin-right: 10px;

        .user-initials {
            padding-top: 8px;
        }
    }
}

.not-found-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .centered {
        text-align: center;
        margin-top: 20%;
    }
}

.ant-tooltip-inner {
    font-size: 12px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #555;
}

.subtask-container {
    margin-bottom: 20px;

    .subtask-entry {
        padding: 4px 5px;
        display: flex;
        justify-content: space-between;

        &:hover {
            background-color: rgba(9, 30, 66, .04);;
        }

        svg {
            cursor: pointer;
            position: relative;
            top: 7px;
            font-size: 16px;

            :hover {
                color: black;
            }
        }

        .left {
            width: 100%;
            display: flex;

            .status-cell {
                height: 30px;
                width: 90px;
                margin-right: 8px;
                background-color: #efefef;
                flex-shrink: 0;
                flex-basis: 90px;

                .dropdown-menu.show{
                    transform: translate3d(-92px, 48px, 0px) !important;
                }

                button{
                    font-size: 11px;
                }
            }

            .edit-row-name{
                align-items: center;
                justify-content: center;
                width: 100%;

                &>div{
                    width: 100%;
                }

                .row-name{
                    width: 100% !important;
                    input{
                        width: 100% !important;
                        max-width: 100% !important;
                    }
                }
            }
        }

    }
}

.automations-list{
    .automation-card{
        padding: 14px;
        padding-bottom: 36px;
        display: block;
        border: 1px solid #efefef;
        border-radius: 3px;
        margin-bottom: 7px;
        position: relative;
        padding-top: 18px;

        .blue{
            color:#40a9ff;
        }
        .description{
            font-size: 16px;
            font-weight: 500;
            max-width: 100%;
        }
        small{
            position: absolute;
            bottom: 7px;
            left: 14px;
        }
        .switch{
            margin-top: 5px;
        }
        .delete{
            position: absolute;
            top: 7px;
            right: 7px;
            font-size: 15px;
            cursor: pointer;
        }
    }

    &>button{
        float: right;
        margin-top: 20px;
    }
}

.new-automation{
    font-size: 20px;

    .ant-checkbox-wrapper{
        font-size: 20px;
    }

    .when{
        display: flex;
        p{
            margin-bottom:0;
        }

        &>a{
            //margin-left: 5px;
        }
        .on{
            //margin-left: 5px;
            //margin-right: 5px;
        }
    }

    .column-selection{
        width: 100%;
        //display: flex;
        //flex-wrap: wrap;
        margin-top: 10px;

        p{
            margin-bottom:5px;
        }
        .columns-multi-select{
            width: 100%;
            font-size: 15px;
        }
    }

    .action {
        display: flex;
        margin-top: 10px;

        p{
            margin-bottom:0;
        }
    }

    .users-selection{
        width: 100%;
        margin-top: 10px;
        .users-multi-select{
            width: 100%;
            font-size: 15px;
        }
    }

    .actions-container{
        float:right;
        margin-top: 20px;

        .save{
            margin-left: 5px;
            cursor: pointer;
        }
    }

    .blue{
        color: #1890ff;
    }

    .create-row{
        padding: 1% 2%;
        font-size: 14px;
        .select-board-table{
            font-size: 14px;
        }
        .create-row-container{
            font-size: 16px;
            p{
                margin-bottom: 3px;
            }
        }
        .select-table .name{
            cursor: pointer;
            //color: #1890ff;
        }
    }
}


.save-search-modal{
    .select-team .ant-select{
        display: block;
        width: 50%;
    }
}

.link-upload-modal{
    width: fit-content !important;

    .spinner{
        position: relative;
        width: 13px;
        height: 13px;
        top: 6px;
        left: 5px;
    }

    .link-row{
        td{
            position: relative;

            svg{
                margin: 0px 2px;
            }

            .edit-button{
                color: #096dd9;
                font-size: 18px;
                position: relative;
                top: 4px;
                cursor: pointer;
                line-height: initial;
            }

            .delete-link{
                color: red;
                font-size: 26px;
                cursor: pointer;
                line-height: initial;
            }
            .copy-link{
                color: #1890ff;
                font-size: 15px;
                cursor: pointer;
                line-height: initial;
                margin: 5px;
            }
        }
    }

    .full-row{
        width: 100%;
    }
}

.create-link{
    padding: 12px;
    border: 2px solid rgba(0,0,0,0.1);
    margin: 6px 0px;
    border-radius: 3px;
    .form-label{
        margin-left: 5px;
    }
}



.templates-page {
    //position: relative;

    @media (max-width: 850px) {
        .templates-table-container {
            overflow-x: scroll;
        }
    }

    .filters-bar{
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;

        .search-input{
            align-self: flex-end;
            max-width: 100%;
        }
        .filter-wrapper{
            flex-shrink: 0;
            align-self: flex-start;
            display: flex;
            flex-wrap: nowrap;
            margin-left: 40px;

            p{
                padding: 5px;
                margin-bottom: 0px;
                color: #222;
            }
        }
    }

    @media (max-width: 1000px) {
        .filters-bar{
            flex-wrap: wrap;

            .filter-wrapper{
                margin-left: 0px;
                margin-top: 14px;

                p{
                    width: 80px;
                }
            }
        }
    }

    .actions{
        margin-bottom: 20px;
        button{
            margin-right: 10px;
        }
        .right-actions{
            float:right;
        }

        @media (max-width: 850px) {
            .right-actions{
                float: none;
                margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
                & > button{
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .template-row{
        //cursor: pointer;

        &:hover{
            background-color: rgba(50,50,150,0.06);
        }
    }

    .spinner {
        top: -46px;
        position: absolute;
        right: 4px;
        width: 26px;
        height: 26px;
        color: #1e88e5;
    }

    .template-table {
        color: initial;
        margin-top: 10px;

        p {
            margin-bottom: 0px;
        }

        td {
            vertical-align: middle;
        }

        thead th {
            border-bottom: 0px;
            border-top: 0px;
            color: gray;
            font-weight: 400;
        }

        .edit-icon{
            color: #1e88e5;
            font-size: 18px;
            position: relative;
            top: 4px;
            cursor: pointer;
        }

        .delete-template{
            color: red;
            font-size: 26px;
            cursor: pointer;
        }

        .template-plus-cta{
            border:none;
            color: #1e88e5;
            font-size: 15px;
            position: relative;
            top: 4px;
            margin-right: 5px;
            cursor: pointer;
        }

        .template-name-row{
            cursor: pointer;
        }

        .public .template-plus-cta{
            top: 0px;
        }
    }
}

.create-table-modal, .create-board-modal{
    //width: 700px !important;
    margin-top: 20px;
    margin-bottom: 20px;

    .paddingleft20{
        padding-left: 20px;
    }

    .search-actions{
        margin-bottom: 20px;
        .filters-bar{
            display: flex;
            margin-bottom: 20px;
            .filter-wrapper{
                display: flex;
                &>*{
                    display: flex;
                }
            }
        }
    }

    .template-row{
        cursor: pointer;
    }

    .template-switch{
        display: block;
        margin-bottom: 0.5rem;
    }

    .categories-select__value-container{
        &>div{
            width: initial !important;
        }
    }
}

.template-preview-drawer{
    overflow: inherit !important;

    .spinner{
        width: 26px;
        height: 26px;
        color: #1e88e5;
        float:left;
    }
    .asset-actions{
        display: none !important;
    }

    .assets-table-container, .copy-table-container{
        padding-left: 18px;
    }

    .resizable-column-header{
        height: 45px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        min-width: 300px;
        h4{
            margin-bottom: 0px;
            padding: 6px;
        }
    }

    .fake-data-alert{
        margin-bottom: 20px;
    }

    .column-header{
        height: 45px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        width: 160px;
        text-align: center;
    }

    .row-name-container{
        min-width: 300px;
    }

    .row-entry:last-child .row-name-container, .row-entry:last-child .cell-entry{
        border-bottom: 1px solid #ccc;
    }

    .status-cell{
        color: white;
        outline: none;
        /* border: 1px solid #000; */
        border-radius: 0px;
        width: 100%;
        height: 100%;
        p{
            margin: 0;
            padding: 0;
            height: 100%;
            padding-top: 10px;
            font-weight: 500;
            font-size: 15px;
        }
    }

    .people-cell{
        outline: none;
        border-radius: 0px;
        width: 100%;
        height: 100%;
        padding: 8px;
    }
}

.tcs-modal{
    h3{
        color: #555;
        font-size: 24px;
        //margin-top: 25px;
    }
}

.droppable-tables{
    &>*{
        margin-bottom: 13px;
    }
    .table-type {
        max-width: 100%;
        //border: 1px solid red;
        overflow-x: auto;
        width: calc(100vw - 221px);
        position: relative;
        left: -3px;
    }
    @media (max-width: 768px) {
        &>*{
            margin-top: 15px;
        }
        .table-type {
            width: calc(100vw - 21px);
            left: 0px;
        }
        .copy-component, .asset-component{
            width: 100%;
        }
        .table-wrapper{
            padding-left: 0px;
        }
    }
    @media (max-width: 1400px) {
        .copy-component, .asset-component{
            width: 97%;
        }
    }

}

.ant-empty{
    margin-top: 90px;
}

.ant-empty-image{
    height: 80px !important;
}

.ant-empty-description{
    max-width: 400px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.onboarding-page{
    height: 100vh;
    position: relative;

    p{
        font-size: 20px;
        margin-bottom: 0;
    }

    .skip{
        position: absolute;
        right: 20px;
        bottom: 20px;
        border: none;
        color: #1890ff;
        background: none;
        font-size: 18px;
    }

    .next{
        margin-top: 20px;
        //margin-left: 20px;
        border: 2px solid #1890ff;
        background: none;
        font-size: 18px;
        border-radius: 3px;
        padding: 7px 20px;

        &:hover{
            color: white;
            background: #1890ff;
        }
    }

    .prev{
        margin-top: 20px;
        //margin-left: 20px;
        border: 0px solid #1890ff;
        background: none;
        font-size: 18px;
        border-radius: 3px;
        padding: 7px 20px;
        color: #1890ff;
        margin-right: 10px;
    }

    .done{
        animation: changebg 2.5s linear infinite;
    }

    @keyframes changebg {
        0% {
            color: #1890ff;
            background: none;
        }
        50% {
            background-color: #1890ff;
            color: white;
        }
        100% {
            color: #1890ff;
            background: none;
        }
    }

    .done {
        -webkit-animation-name: changebg;
        animation-name: changebg;
    }

    .buttons{
        display: flex;
    }

    .triangle-topleft {
        width: 0;
        height: 0;
        border-top: 42vh solid #bae7ff;
        border-right: 35vh solid transparent;
        position: absolute;
        top: 0;
        left: 0;
    }

    //pages

    .intro {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .page{
        display: flex;
        align-items: center;
        height: 100%;
    }

    .page1{
        .wrapper{
            text-align: left;
            width: 50%;
            padding-left: 6%;
            padding-right: 8%;
        }
        .board-container{
            margin-left: 4%;
            h2{
                margin-bottom: 3px;
                font-size: 23px;
            }

            .board-wrapper{
                padding: 3%;
                border: 1px solid #333;
                border-radius: 3px;

                &>*{
                    margin-bottom: 30px;
                    border: 1px solid #333;
                    padding: 20px;
                    border-radius: 2px;
                }
                img{
                    width: 100%;
                }

                h3{
                    font-size: 21px;
                    margin-bottom: 3px;
                    position: relative;
                    top: -8px;
                }
            }
        }
    }

    .page2{
        .left{
            h1{
                margin-bottom: 13px;
                font-size: 32px;
                color: white;
            }
            p{
                color: whitesmoke;
            }
            //width: 32%;
            padding-left: 4%;
            padding-right: 4%;
            height: 100%;
            //background: #f9f9f9;
            background: #222;
            display: flex;
            align-items: center;
            color:white;

            input{
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 20px;
                width: 360px;
            }
        }
        .first-table{
            position: relative;
            padding-bottom: 5px;
            margin-bottom: 5px;
            .arrow-info{


                .highlight-table, .highlight-richtext, .highlight-assets{
                    position: absolute;
                    top: 70px;
                    right: -38px;
                    color: black;
                    width: 140px;
                    display: inline-block;
                    font-size: 16px !important;
                    line-height: 17px;
                    font-weight: 500;
                    animation: float 4s ease-in-out infinite;
                    padding-top: 5px;
                    text-align: center;
                }
                .highlight-richtext{
                    right: -16px;
                }
            }
        }
        .starting-table-button, .rich-text-cta-button, .assets-cta-button{
            margin-right: 7px;
            background-color: #323232;
            border: 1px solid #323232;
            color: white;
        }


        .right{
            width: 65%;
            padding-left: 4%;
            height: 100%;
            display: flex;
            align-items: center;

            .board-element{
                max-width: 100%;
            }

            p{
                font-size: 14px;
            }

            .board{
                width: 100%;
            }
        }
        .wrapper{
            text-align: left;
        }
    }

    .add-elements-container{
        margin-top: 30px;
        display: block;
        width: 100%;

        &>*{
            margin-right: 5px;
        }
        svg{
            position: relative;
            top: -3px;
        }

        .ant-row{
            margin-bottom: 15px;
            &>p{
                padding: 5px;
                margin-right: 10px;
                //color: #1890ff;
                color: #444;
                font-weight: 600;
            }
            &>button{
                margin-right: 7px;
                background-color: #777;
                border: 1px solid #777;
                color: white;
                &.clickable{
                    background-color: #323232;
                    border: 1px solid #323232;
                }
            }
        }
    }

    .copy-type, .asset-table-type{
        margin-top: 18px;
        h3{
            font-size: 24px;
            padding-left: 9px;
        }

        .copy-component .actions{
            display: flex;
            margin-top: 10px;
            margin-left: auto;
            justify-content: flex-end;
            padding-right: 0;
            button{
                margin-left: 10px;
            }
            &.desktop{
                position: absolute;
                top: -5px;
                right:0px;
            }
        }
        .copy-component .actions.mobile{
            display: none;
        }
        .copy-view{
            position: relative;
        }
    }


    .table-wrapper{
        padding-left: 0px;
        position: relative;

        & .row-line:last-child .row-entry:last-child .cell-entry,
        & .row-line:last-child .row-entry:last-child .row-name-container{
            border-bottom: 1px solid #ccc;
        }


        .create-row-info{
            position: relative;
            #highlight-assign{
                position: absolute;
                top: 70px;
                right: -48px;
                color: black;
                width: 190px;
                display: inline-block;
                font-size: 16px !important;
                line-height: 17px;
                font-weight: 500;
                animation: float 4s ease-in-out infinite;
                padding-top: 5px;
                text-align: center;
            }
        }

        .table-header{
            position: relative;
            .add-column-assign{
                position: relative;
                #add-column-assign{
                    position: absolute;
                    top: -120px;
                    right: -28px;
                    color: black;
                    width: 190px;
                    display: inline-block;
                    font-size: 16px !important;
                    line-height: 17px;
                    font-weight: 500;
                    animation: float 4s ease-in-out infinite;
                    padding-top: 5px;
                    text-align: center;

                    p{
                        font-size: 14px;
                        background: $background-color;
                        padding-bottom: 1px;
                    }
                }
            }
        }
    }

    .table-footer{
        position: relative;
        .create-row-info{
            position: relative;
            #highlight-create-row{
                position: absolute;
                top: 70px;
                right: 72px;
                color: black;
                width: 140px;
                display: inline-block;
                font-size: 16px !important;
                line-height: 17px;
                font-weight: 500;
                animation: float 4s ease-in-out infinite;
                padding-top: 5px;
                text-align: center;
            }
        }
    }

    @media (max-width: 850px) {
        .intro{
            padding: 5%;
        }
        .page{
            flex-direction: column;
        }
        .skip{
            position: fixed;
        }
        .page1{
            .wrapper{
                text-align: left;
                width: 96%;
                padding: 4%;
                p{
                    margin-bottom: 5px;
                    br{
                        display: none;
                    }
                }
            }
            .board-container{
                margin-left: 4%;
                margin-right: 4%;
            }
        }
        .page2{
            .left{
                padding: 4%;
                width: 100% !important;
                height: auto;
            }
            .right{
                padding: 4%;
                width: 100% !important;
                height: auto;
                .board{
                    .table-type{
                        overflow-x: scroll;
                    }
                }
            }
        }
    }
}



@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(5px);
    }
    100% {
        transform: translatey(0px);
    }
}

.float {
    -webkit-animation-name: float;
    animation-name: float;
}

.copy-view .ql-container.ql-disabled .ql-tooltip{
    display: none;
}